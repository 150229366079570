import React from 'react'
import { Link } from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import serviceIcon1 from '../../assets/images/services/service-icon1.png'
import serviceIcon2 from '../../assets/images/services/service-icon2.png'
import serviceIcon3 from '../../assets/images/services/service-icon3.png'

const FeaturedServices = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="features" />
                        Our Services
                    </span>
                    <h2>Our Featured Services</h2>
                    <p>With our seamless automation frameworks, tools and consulting services, we assist you in achieving the quality of your web, API, and mobile applications, DevOps goals..</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon1} alt="features" />
                            </div>
                            <h3>
                                <Link to="#">
                                    Test Automation
                                </Link>
                            </h3>
                            <p>Our Web, API, and Mobile automation testing services are designed to reduce repetitive tasks and manual efforts, resulting in increased efficiency and effectiveness. With our Software Testing Automation, we achieve faster time to market, consistent, and accurate results. Our BDD, TDD, ATDD, and DevOps Services provide a quick return on investment..</p>

                            {/* <Link to="/" className="read-more-btn">
                                Read More
                                <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon2} alt="features" />
                            </div>
                            <h3>
                                <Link to="#">
                                    Consulting
                                </Link>
                            </h3>
                            <p>We offer IT staffing services for entry-level, mid-level, and senior-level positions.  Our comprehensive technological solutions at KommForce assist industry leaders and individuals in achieving greater success. Our platform includes a variety of features designed and  constantly improving it's own technology in order to empower our users to do better.Contact us today for a free demo.</p>

                            {/* <Link to="/" className="read-more-btn">
                                Read More
                                <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon3} alt="features" />
                            </div>

                            <h3>
                                <Link to="#">
                                    Our Products
                                </Link>
                            </h3>
                            <p> Our Test Execution Platform product simple and efficient for cross-browser testing and rapid parallel executions.
                                Data as a Service (DaaS) enables the generation and maintenance of high-quality test data.
                                Accessibility Automation solutions offer a path to removing WCAG compliance issues.
                                Leader board solutions provides visibility into quality metrics.</p>

                            {/* <Link to="/" className="read-more-btn">
                                Read More
                                <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FeaturedServices