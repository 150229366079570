import React from 'react'
import service4 from '../../assets/images/services/service4.png'
import service5 from '../../assets/images/services/service5.png'
import service6 from '../../assets/images/services/service6.png'

const Services = () => {
    return (
        <section className="overview-area ptb-100 pt-0">
            <div className="container">
                {/* Left Image Style */}
                <div className="overview-box">
                    <div className="overview-image">
                        <div className="image">
                            <img src={service4} alt="features" />
                        </div>
                    </div>

                    <div className="overview-content">
                        <div className="content right-content">
                            <span className="sub-title">Design & Development</span>
                            <h2>Automation frameworks for all kinds of platforms</h2>
                            <p>We build automation frameworks that covers language platforms like Java, NodeJS, C# and Big data .</p>

                            <ul className="features-list">
                                <li><span><i className='bx bx-check'></i> Web Automation</span></li>
                                <li><span><i className='bx bx-check'></i> API Automation</span></li>
                                <li><span><i className='bx bx-check'></i> Mobile Automation</span></li>
                                <li><span><i className='bx bx-check'></i> Accessibility Automation</span></li>
                                <li><span><i className='bx bx-check'></i> Test Data As A Service (TDAAS)</span></li>
                                <li><span><i className='bx bx-check'></i> Onpremises Parallel Execution</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* End Left Image Style */}

                {/* Right Image Style */}
                <div className="overview-box">
                    <div className="overview-content">
                        <div className="content">
                            <span className="sub-title">TDAAS DATA SOLUTION</span>
                            <h2>Test Data As a Service</h2>
                            <p>We saw lot of times when we have fixed data in excel sheets or hard coded data cause for many automation failures to overcome we have developed Test Data As A Service solution that works seemlesly.</p>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Dynamic Data Mechanism</h4>
                                <p>Many customers has challenge to maintain valid data because of varios reasons to get data corrupted and once automation run data state will get change to bring that data back to the original state will be quicker and neet.</p>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Get Data as api call</h4>
                                <p>Data required with variouse condtions our rule engine mechanism can filter data seemlesly and can be supplied as a service call for individual test conditions.</p>
                            </div>
                        </div>
                    </div>

                    <div className="overview-image">
                        <div className="image">
                            <img src={service5} alt="features" />
                        </div>
                    </div>
                </div>
                {/* End Right Image Style */}

                {/* Left Image Style */}
                <div className="overview-box">
                    <div className="overview-image">
                        <div className="image">
                            <img src={service6} alt="features" />
                        </div>
                    </div>

                    <div className="overview-content">
                        <div className="content right-content">
                            <span className="sub-title">BROWSER LAB</span>
                            <h2>KommForce Test Execution Lab (KFTEL)</h2>
                            <p>KFTEL is a browser automation solution compatible with Selenium, Cypress, Playwright and Puppeteer, Simple and extremely efficient solutions
                                for your Selenium test infrastructure .</p>

                            <ul className="features-list">
                                <li><span><i className='bx bx-check'></i> KFTEL-Run in your own cloud VMs</span></li>
                                <li><span><i className='bx bx-check'></i> KFTEL-Run on your own Kubernetes or Openshift</span></li>
                                <li><span><i className='bx bx-check'></i> KommForce Solutions if any challenges</span></li>
                                <li><span><i className='bx bx-check'></i> 24/7 Support</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* End Left Image Style */}
            </div>
        </section>
    )
}

export default Services